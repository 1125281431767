.RSPBprogressBar{
    width: 100%;
    padding: 10px 30px;
}
@media (max-width: 767px){
    .RSPBprogressBars.d-sms-none{
        display: none;
    }
}
.stepBox{
    padding: 10px 25px;
    width: auto;
    height: auto;
}
.br_none{
    border: none !important;
    border-radius: unset !important;
}
.flex{
    display: flex;
    width: 100%;
}
.center{
    justify-content: center;
}
.space-between{
    justify-content: space-between;
}
.darkStep{
    background: var(--secondary);
    color: #fff;
}
.lightStep{
    background: var(--primary);
    color: #fff;
}
.steps{
    padding: 20px 25px;
    border-left: 1px solid #fff;
    flex: 1;
    text-align: center;
}